<template>
  <div class="app-context">
    <!-- <app-header :headerOption="headerOption" ref="appheader"></app-header> -->
    <div class="context">
      <directive :directiveInfo="directiveInfo"></directive>
      <div class="agreement">
        <div class="a-action" @click="allCheckAgree()">
          <div class="check">
            <img src="@/assets/images/ck_activated.svg" v-if="agreeChecked" />
            <img src="@/assets/images/ck_unActivated.svg" v-else />
          </div>
          <h4>전체약관 동의</h4>
        </div>
        <div class="article">
          <div
            class="item"
            v-for="(agreeType, index) in agreement"
            :key="index"
            @click="readAgreement(index)"
          >
            <div class="i-slot">
              <div class="check">
                <img src="@/assets/images/ck_on.svg" v-if="agreeType.flag" />
                <img src="@/assets/images/ck_un.svg" v-else />
              </div>
              <h4>{{ agreeType.type }}</h4>
            </div>
            <div class="btn-wrap">
              <button type="button" v-if="!agreement[index].flag">
                내용보기
              </button>
              <button type="button" v-else class="default">동의취소</button>
            </div>
          </div>
          <div class="j-article first">
            <h5 class="title">동의자명</h5>
            <div class="input-wrap">
              <input
                type="text"
                placeholder="성함을 입력해주세요"
                v-model="form.consenter.value"
                @keyup="checkConsenter()"
                @blur="checkConsenter()"
                maxlength="30"
              />
            </div>
            <p class="warn-message" v-if="form.consenter.hasError">
              {{ form.consenter.errorTxt }}
            </p>
          </div>
          <div class="j-article">
            <h5 class="title">관계</h5>
            <div class="input-wrap">
              <input
                type="text"
                placeholder="동의자와의 관계를 입력해주세요"
                v-model="form.relation.value"
                @keyup="checkRelation()"
                @blur="checkRelation()"
                maxlength="30"
              />
            </div>
            <p class="warn-message" v-if="form.relation.hasError">
              {{ form.relation.errorTxt }}
            </p>
          </div>
          <div class="j-article">
            <h5 class="title">생년월일</h5>
            <div class="input-wrap">
              <input
                type="text"
                placeholder="YYYY-MM-DD"
                v-model="form.birth.value"
                @keyup="checkBirth()"
                @blur="checkBirth()"
                maxlength="8"
              />
            </div>
            <p class="warn-message" v-if="form.birth.hasError">
              {{ form.birth.errorTxt }}
            </p>
          </div>
        </div>
        <div
          class="btn-wrap"
          :class="{
            active:
              this.agreement[0].flag &&
              form.consenter.flag &&
              form.relation.flag &&
              form.birth.flag,
          }"
        >
          <button type="button" @click="agree()">동의</button>
        </div>
      </div>
    </div>
    <!-- <app-footer></app-footer> -->
    <agreement-popup
      @agree-checked="checkAgreement($event)"
      v-if="checkAgreementPopup"
      :popupSetAgree="popupSetAgree"
    />
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
// import AppHeader from "@/components/AppHeader";
import Directive from "@/components/join/Directive";
import AgreementPopup from "@/components/modal/AgreementPopup";
import DefaultPopup from "@/components/modal/DefaultPopup";
// import AppFooter from "@/components/AppFooter";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    Directive,
    AgreementPopup,
    DefaultPopup,
    // AppHeader,
    // AppFooter,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      directiveInfo: {
        title: "개인정보제공동의",
        isTitleNewLine: false,
        content: null,
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      agreeChecked: false,
      agreement: [
        {
          flag: false,
          type: "[필수] 개인정보 제공동의",
        },
        {
          flag: false,
          type: "[선택] 개인정보 제공동의",
        },
        {
          flag: false,
          type: "[선택] 회신동의",
        },
        {
          flag: false,
          type: "[선택] 피드백 수신동의",
        },
      ],
      form: {
        consenter: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*동의자명을 정확히 입력해주세요",
        },
        relation: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*동의자와의 관계를 입력해주세요",
        },
        birth: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*-을 제외한 숫자만을 입력해주세요",
        },
      },
      popupSet: {},
      popupSetAgree: {},
      apiSet: {},
    };
  },
  created() {
    this.getStoreAgreement();
  },
  mounted() {},
  computed: {
    ...mapState("basic", ["checkPopup", "checkAgreementPopup"]),
    genetateHyphen() {
      return (numData, isBirth) => {
        //console.log("가져온 번호들", numData);
        //console.log("팩스인가?", isBirth);
        const number = numData.toString().replace(/[^0-9]/g, "");
        let generatedNum = "";
        if (isBirth) {
          if (number.length < 5) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4, 2);
            generatedNum += "-";
            generatedNum += number.substr(6);
          }
          this.form.birth.value = generatedNum;
        } else {
          if (number.length < 4) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 3);
            generatedNum += "-";
            generatedNum += number.substr(6);
          } else {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 4);
            generatedNum += "-";
            generatedNum += number.substr(7);
          }
          this.form.phone.value = generatedNum;
        }
        return generatedNum;
      };
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_AGREEMENT_POPUP"]),
    getStoreAgreement() {},
    checkFlag() {
      if (
        !this.agreement[0].flag ||
        !this.form.consenter.flag ||
        !this.form.relation.flag ||
        !this.form.birth.flag
      ) {
        if (!this.agreement[0].flag) {
          this.SET_POPUP(true);
          this.popupSet.title = "동의여부 확인";
          this.popupSet.content = "필수 동의항목을 확인해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return false;
        }
        if (!this.form.consenter.flag) {
          this.SET_POPUP(true);
          this.popupSet.title = "동의자명 확인";
          this.popupSet.content = "동의자명을 입력해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          this.form.consenter.hasError = true;
          return false;
        }
        if (!this.form.relation.flag) {
          this.SET_POPUP(true);
          this.popupSet.title = "관계 확인";
          this.popupSet.content = "관계를 입력해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          this.form.relation.hasError = true;
          return false;
        }
        if (!this.form.birth.flag) {
          this.SET_POPUP(true);
          this.popupSet.title = "생년월일 확인";
          this.popupSet.content = "생년월일을 입력해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          this.form.birth.hasError = true;
          return false;
        }
      }
      return true;
    },
    agree() {
      if (this.checkFlag()) {
        this.SET_POPUP(true);
        this.popupSet.title = "동의확인";
        this.popupSet.content = "동의내역이 확인되었습니다.";
        this.popupSet.popType = "custom";
        this.popupSet.cancelBtnText = undefined;
        this.popupSet.confirmBtnText = "확인";
        this.popupSet.nextLink = "/v/request/requestDocument";
      }
    },
    allCheckAgree() {
      this.agreeChecked = !this.agreeChecked;
      this.allCheckOther(this.agreeChecked);
    },
    allCheckOther(isChecked) {
      //("isChecked", isChecked);
      for (let j = 0; j < this.agreement.length; j++) {
        //console.log("바꿀 대상", this.agreement[j]);
        this.agreement[j].flag = isChecked;
      }
      //console.log("결과", this.agreement);
    },
    checkAgreement(order) {
      //console.log("order", order);
      this.agreement[order].flag = !this.agreement[order].flag;
      this.checkOther();
    },
    checkOther() {
      for (let i = 0; i < this.agreement.length; i++) {
        if (!this.agreement[i].flag) {
          this.agreeChecked = false;
          return false;
        }
      }
      this.agreeChecked = true;
    },
    readAgreement(type) {
      if (this.agreement[type].flag) {
        this.agreement[type].flag = !this.agreement[type].flag;
        this.agreeChecked = false;
      } else {
        if (type === 0) {
          this.popupSetAgree.agreeComment1 =
            "의사 회원만 이용 가능한 진료의뢰 플랫폼 닥터리퍼에 오신 것을 환영합니다. " +
            "닥터리퍼는 1차 의원 및 2차 병원급 의료진의 전문성을 활용하고 " +
            "의사들의 네트워킹을 강화하기 위한 플랫폼입니다.";
          this.popupSetAgree.agreeComment2 =
            "회원 가입을 하시면, 전문성 높은 의사를 쉽게 검색할 수 있고 " +
            "전자 진료의뢰서를 작성하여 환자를 의뢰할 수 있고 " +
            "진료 내용을 회신* 받을 수 있습니다. (*환자 동의 필수)";
          this.popupSetAgree.agreeComment3 =
            "회원 가입 후 정보 입력만 하시면, 나만의 진료의뢰서 양식과 병원 소개페이지가 " +
            "자동 생성되고 전문 분야 키워드 입력을 통해 다른 회원의 검색 대상이 될 수 있고," +
            "자신의 전문 분야에 적합한 환자를 의뢰 받을 수 있습니다.";
          this.popupSetAgree.agreeComment4 =
            "(진료의뢰서 및 병원 소개페이지 샘플 그림 삽입)";
          this.popupSetAgree.agreeComment5 =
            "기존의 종이 진료의뢰서 인쇄나 배포가 더 이상 필요 없고 진료 후 회신서 작성 및";
          this.popupSetAgree.agreeComment6 =
            "발송 과정이 온라인으로 처리됩니다.";
          this.popupSetAgree.agreeCheckNum = 0;
        } else if (type === 1) {
          this.popupSetAgree.agreeComment1 =
            "의사 회원만 이용 가능한 진료의뢰 플랫폼 닥터리퍼에 오신 것을 환영합니다. " +
            "닥터리퍼는 1차 의원 및 2차 병원급 의료진의 전문성을 활용하고 " +
            "의사들의 네트워킹을 강화하기 위한 플랫폼입니다.";
          this.popupSetAgree.agreeComment2 =
            "회원 가입을 하시면, 전문성 높은 의사를 쉽게 검색할 수 있고 " +
            "전자 진료의뢰서를 작성하여 환자를 의뢰할 수 있고 " +
            "진료 내용을 회신* 받을 수 있습니다. (*환자 동의 필수)";
          this.popupSetAgree.agreeComment3 =
            "회원 가입 후 정보 입력만 하시면, 나만의 진료의뢰서 양식과 병원 소개페이지가 " +
            "자동 생성되고 전문 분야 키워드 입력을 통해 다른 회원의 검색 대상이 될 수 있고," +
            "자신의 전문 분야에 적합한 환자를 의뢰 받을 수 있습니다.";
          this.popupSetAgree.agreeComment4 =
            "(진료의뢰서 및 병원 소개페이지 샘플 그림 삽입)";
          this.popupSetAgree.agreeComment5 =
            "기존의 종이 진료의뢰서 인쇄나 배포가 더 이상 필요 없고 진료 후 회신서 작성 및";
          this.popupSetAgree.agreeComment6 =
            "발송 과정이 온라인으로 처리됩니다.";
          this.popupSetAgree.agreeCheckNum = 1;
        } else if (type === 2) {
          this.popupSetAgree.agreeComment1 =
            "의사 회원만 이용 가능한 진료의뢰 플랫폼 닥터리퍼에 오신 것을 환영합니다. " +
            "닥터리퍼는 1차 의원 및 2차 병원급 의료진의 전문성을 활용하고 " +
            "의사들의 네트워킹을 강화하기 위한 플랫폼입니다.";
          this.popupSetAgree.agreeComment2 =
            "회원 가입을 하시면, 전문성 높은 의사를 쉽게 검색할 수 있고 " +
            "전자 진료의뢰서를 작성하여 환자를 의뢰할 수 있고 " +
            "진료 내용을 회신* 받을 수 있습니다. (*환자 동의 필수)";
          this.popupSetAgree.agreeComment3 =
            "회원 가입 후 정보 입력만 하시면, 나만의 진료의뢰서 양식과 병원 소개페이지가 " +
            "자동 생성되고 전문 분야 키워드 입력을 통해 다른 회원의 검색 대상이 될 수 있고," +
            "자신의 전문 분야에 적합한 환자를 의뢰 받을 수 있습니다.";
          this.popupSetAgree.agreeComment4 =
            "(진료의뢰서 및 병원 소개페이지 샘플 그림 삽입)";
          this.popupSetAgree.agreeComment5 =
            "기존의 종이 진료의뢰서 인쇄나 배포가 더 이상 필요 없고 진료 후 회신서 작성 및";
          this.popupSetAgree.agreeComment6 =
            "발송 과정이 온라인으로 처리됩니다.";
          this.popupSetAgree.agreeCheckNum = 2;
        } else if (type === 3) {
          this.popupSetAgree.agreeComment1 =
            "의사 회원만 이용 가능한 진료의뢰 플랫폼 닥터리퍼에 오신 것을 환영합니다. " +
            "닥터리퍼는 1차 의원 및 2차 병원급 의료진의 전문성을 활용하고 " +
            "의사들의 네트워킹을 강화하기 위한 플랫폼입니다.";
          this.popupSetAgree.agreeComment2 =
            "회원 가입을 하시면, 전문성 높은 의사를 쉽게 검색할 수 있고 " +
            "전자 진료의뢰서를 작성하여 환자를 의뢰할 수 있고 " +
            "진료 내용을 회신* 받을 수 있습니다. (*환자 동의 필수)";
          this.popupSetAgree.agreeComment3 =
            "회원 가입 후 정보 입력만 하시면, 나만의 진료의뢰서 양식과 병원 소개페이지가 " +
            "자동 생성되고 전문 분야 키워드 입력을 통해 다른 회원의 검색 대상이 될 수 있고," +
            "자신의 전문 분야에 적합한 환자를 의뢰 받을 수 있습니다.";
          this.popupSetAgree.agreeComment4 =
            "(진료의뢰서 및 병원 소개페이지 샘플 그림 삽입)";
          this.popupSetAgree.agreeComment5 =
            "기존의 종이 진료의뢰서 인쇄나 배포가 더 이상 필요 없고 진료 후 회신서 작성 및";
          this.popupSetAgree.agreeComment6 =
            "발송 과정이 온라인으로 처리됩니다.";
          this.popupSetAgree.agreeCheckNum = 3;
        }
        this.SET_AGREEMENT_POPUP(true);
        this.popupSetAgree.footerLess = true;
        this.popupSetAgree.isHuge = true;
      }
    },
    checkConsenter() {
      this.form.consenter.flag = false;
      this.form.consenter.hasError = true;
      const Consenter = this.form.consenter.value.replace(/\s/gi, "");
      if (Consenter === "") {
        this.form.consenter.flag = false;
        return false;
      }
      this.form.consenter.flag = true;
      this.form.consenter.hasError = false;
      return true;
    },
    checkRelation() {
      this.form.relation.flag = false;
      this.form.relation.hasError = true;
      const Relation = this.form.relation.value.replace(/\s/gi, "");
      if (Relation === "") {
        this.form.relation.flag = false;
        return false;
      }
      this.form.relation.flag = true;
      this.form.relation.hasError = false;
      return true;
    },
    checkBirth() {
      this.form.birth.flag = false;
      this.form.birth.hasError = true;
      const birth = this.form.birth.value.replace(/\s/gi, "");
      if (birth === "") {
        this.form.birth.flag = false;
        this.form.birth.hasError = true;
        this.form.birth.errorTxt = "*생년월일을 입력해주세요.";
        return false;
      }
      const isBirth = /^[0-9]{8}$/;
      if (!isBirth.test(birth)) {
        this.form.birth.errorTxt = "*YYYY-MM-DD 형식에 맞춰서 입력해주세요.";
        this.form.birth.flag = false;
        this.form.birth.hasError = true;
        return false;
      }
      this.form.birth.flag = true;
      this.form.birth.hasError = false;
      this.form.birth.errorTxt = "";
      return true;
    },
  },
  destroyed() {
    if (this.checkPopup !== undefined && this.checkPopup === true) {
      this.SET_POPUP(false);
    } else if (
      this.checkAgreementPopup !== undefined &&
      this.checkAgreementPopup === true
    ) {
      this.SET_AGREEMENT_POPUP(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.agreement {
  width: 720px;
  padding: 0;
  margin: 85px auto 30px auto;
  min-height: 630px;
  position: relative;
  &:after {
    content: "";
    background: #146f83;
    width: 100%;
    height: 3px;
    top: -20px;
    position: absolute;
  }
  .a-action {
    display: flex;
    padding: 0 0 20px 0;
    border: 0px solid #707070;
    border-width: 0px 0px 1px 0px;
    align-items: center;
    cursor: pointer;
    .check {
      margin: 3px 30px 0 0;
      img {
      }
    }
    h4 {
      font-family: "SUIT";
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 29px;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }
  .article {
    margin: 20px 0;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin: 17.5px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      .i-slot {
        display: flex;
        .check {
          margin: 0 30px 0 0;
          display: flex;
          img {
          }
        }
        h4 {
          font-family: "SUIT";
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
        }
      }
      .btn-wrap {
        margin: 0;
        height: 55px;
        width: 110px;
        background: transparent;
        button {
          background: #146f83;
          &.default {
            background: #474747;
          }
        }
      }
    }
  }
  .btn-wrap {
    margin: 45px 0 0 0;
    background: #bcbcbc;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      background: #146f83;
    }
    button {
      background: transparent;
      border: 0;
      font-family: "SUIT";
      font-size: 21px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      width: 100%;
      height: inherit;
    }
  }
  .j-article {
    &.first {
      margin: 50px 0 0 0;
    }
    margin: 30px 0 0 0;
    h5.title {
      margin: 0 0 25px 0;
      font-family: "SUIT";
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
    .input-wrap {
      border: solid 1px #707070;
      position: relative;
      input {
        border: 0;
        width: 100%;
        height: auto;
        background: transparent;
        font-family: "SUIT";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 60px;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        padding: 0 12px;
      }
    }
    p.warn-message {
      margin: 20px 0 0 0;
      font-family: "SUIT";
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 27px;
      letter-spacing: normal;
      text-align: left;
      color: red;
    }
  }
}
</style>
